import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=36a03c69&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=36a03c69&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a03c69",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.vub/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BTab, BTabs, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BTab, BTabs, BContainer})
    

export default component.exports